<template>
  <div class="rateStar">
    <van-rate
      v-model="value"
      :size="14"
      color="#F7B500"
      void-icon="star"
      void-color="#D8D8D8"
      @change="change"
    />
  </div>
</template>

<script>
// import { } from 'vuex'
export default {
  name: 'RateStar',
  components: {},
  props: { },
  data() {
    return {
      value: 5
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    change(value) {
      this.$emit('onChange', value);
    }
  }

};
</script>

<style lang="scss">
.rateStar{
  .van-rate__item:not(:last-child){
    margin-right: 32px;
  }
}
</style>
